<template>
	<div>
		<div
			class="personal-row"
			v-for="row in data"
			:key="row.header"
		>
			<div class="personal-row__col personal-row__col_small">
				<img
					class="personal-row__photo"
					:src="row.photo"
					v-if="row.photo"
				>
			</div>
			<div class="personal-row__col personal-row__col_small">
				<div class="personal-row__header">ID</div>
				<div class="personal-row__body">{{ row.id }}</div>
			</div>
			<div class="personal-row__col">
				<div class="personal-row__header">ФИО</div>
				<div class="personal-row__body">{{ row.name }}</div>
			</div>
			<div class="personal-row__col">
				<div class="personal-row__header">Должность</div>
				<div class="personal-row__body">{{ row.job }}</div>
			</div>
			<div class="personal-row__col">
				<div class="personal-row__header">Телефон</div>
				<div class="personal-row__body">{{ row.phone }}</div>
			</div>
			<div
				class="personal-row__control"
				v-if="row.job !== 'Владелец'"
			>
				<a
					href="#"
					class="personal-row__remove"
					@click.prevent="toggleActive(row.id)"
				/>
				<DropList
					class="personal-row__droplist"
					v-if="isActive(row.id)"
					@change="update"
					:data="options"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import {mapState} from "vuex";

export default {
	components: {
		DropList: () => import('@/components/block/Rest/DropList.vue')
	},
	props: ['data'],
	data()
	{
		return {
			rows: this.data,
			options: [{
				value: 'edit',
				label: 'Редактировать'
			}, {
				value: 'delete',
				label: 'Удалить сотрудника'
			}],
			activeItem: null,
		}
	},
	computed: {
		...mapState(['currentOwnerRestaurant']),
	},
	methods: {
		update(data)
		{
			if (data.value === 'edit')
				this.$emit('openModal', this.activeItem);
			else
				this.removeEmployee(this.activeItem);
			this.toggleActive(null);
		},

		isActive(item)
		{
			return this.activeItem === item;
		},

		toggleActive(item)
		{
			!this.isActive(item) ? this.activeItem = item : this.activeItem = null;
		},

		async removeEmployee(id)
		{
			this.$emit('setError', '');
			this.$emit('setLoading', true);
			this.loading = true;
			let data = qs.stringify({
				id: id,
				currentOwnerRestaurant: this.currentOwnerRestaurant
			});
			let result = await axios.post('/lk/removeEmployeeFromRestaurant/', data);
			if (result.data.success)
			{
				this.$emit('updateList');
			} else
			{
				this.$emit('setError', result.data.error);
			}
			this.$emit('setLoading', false);
		},
	}
}
</script>

<style lang="scss">
$green: #66BE6D;
$black: #3D4248;
$lightgray: #F8F8F8;

.personal-row
{
	background: #FFFFFF;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 16px;
	display: flex;
	border: 1px solid white;
	transition: border .2s ease-in-out;
	cursor: pointer;
	margin-bottom: 8px;

	&:hover
	{
		border: 1px solid $green;
	}

	&__col
	{
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-right: 34px;
		flex-basis: 280px;
		max-width: 280px;

		&_small
		{
			flex-basis: 50px;
			max-width: 50px;
		}
	}

	&__droplist
	{
		position: absolute;
		width: 207px;
		right: -16px;
		top: 40px;
		z-index: 10000;

		& .modal
		{
			padding: 8px 0 !important;
		}

		& span
		{
			font-size: 14px;
			font-weight: 700;
			color: $black;
			padding: 13px 16px;

			&:hover
			{
				background: $lightgray;
			}
		}
	}

	&__control
	{
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		align-items: center;
		position: relative;
	}

	&__photo
	{
		position: relative;
		height: 40px;
		width: 40px;
		border-radius: 50%;
	}

	&__header
	{
		color: #979797;
		font-size: 12px;
		margin-bottom: 5px;
		line-height: 1;
	}

	&__body
	{
		color: $black;
		font-size: 14px;
		font-weight: 700;
		line-height: 1;
	}

	&__remove
	{
		position: relative;
		right: 16px;

		&:after
		{
			position: relative;
			top: 3px;
			content: '\2807';
			font-size: 25px;
			color: #231F20;
			transition: color .2s ease-in-out;
		}

		&:hover
		{
			&:after
			{
				color: $green;
			}
		}
	}
}
</style>